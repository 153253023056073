<script>
import BlogAboutMe from "@/components/BlogAboutMe";
import ButterCMSService from "@/services/ButterCMSService";
export default {
  name: "blog-about-me-page",
  components: {
    BlogAboutMe,
  },
  data() {
    return {
      page_title: "About Me",
      post: {
        data: {
          title: "",
          body: "",
          author: {
            first_name: "",
            last_name: "",
          },
        },
      },
    };
  },
  methods: {
    getPost() {
     this.post = ButterCMSService.getPost("test")
    },
  },
  created() {
    this.getPost()
  },
};
</script>

<template>
  <div id="blog-about">
    <h1 class="title">{{ page_title }}</h1>
    <br />
    <br />
    <br />
    <!-- Create `v-for` and apply a `key` for Vue. Here we are using a combination of the slug and index. -->
    <div class="container">
      <div class="tile is-ancestor">
        <!-- All other tile elements -->
        <div class="tile is-6 is-vertical">
          <div class="aboutsection">
            <h1>{{ post.data.title }}</h1>
            <h4>
              {{ post.data.author.first_name }} {{ post.data.author.last_name }}
            </h4>
            <div v-html="post.data.body"></div>
          </div>
        </div>
        <br />
        <br />
        <div class="tile is-2 is-vertical"></div>
        <div class="tile is-4 is-vertical notification vert-tile-custom">
          <BlogAboutMe />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>