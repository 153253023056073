<template>
  <div id="blog-home">
    <div class="title">Hi I'm Alli!</div>
    <b-image
      :src="require('@/assets/allipreg.png')"
      alt="Image Missing"
      ratio="4by4"
      :rounded="true"
    ></b-image>
    <br />
    <!-- <div class="search-form">
      <b-field>
        <div class="search-input">
          <b-input placeholder="Search..." type="search"> </b-input>
        </div>
      </b-field>
    </div> -->
    <br />
    <div class="funfacts-widget">
      <u><p class="funfacts-title">Fun Facts About Me:</p></u>
      <ul class="funfacts-list">
        <br />
        <li>I am a mother of a 9 month old!</li>
        <br />
        <li>I enjoy hiking, music and reading!</li>
        <br />
        <li>I love horror movies!</li>
        <br />
      </ul>
    </div>
    <br />
    <div class="funfacts-widget">
      <u><p class="funfacts-title">Suggested Third Party Resources</p></u>
      <ul class="funfacts-list">
        <br />
        <li><a href="https://momhelps.org/programs-services/get-help/">Motherhood Get Help</a></li>
        <br />
        <li><a href="https://momhelps.org/programs-services/get-help/">Assistance For PostPartum</a></li>
        <br />
        <li><a href="https://momhelps.org/programs-services/get-help/">Top Foods For Kids</a></li>
        <br />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "blog-about-me",
  data() {
    return {
      page_title: "Blog",
      posts: [],
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss">
</style>