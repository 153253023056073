<template>
  <div class="content has-text-centered coming-soon">
    <div class="container">
      <div class="coming-soon-text">
        <h1>Blog Coming Soon...</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  components: {},
};
</script>
