<script>
import ButterCMSService from "@/services/ButterCMSService";
export default {
  name: "blog-post",
  data() {
    this.butterService = new ButterCMSService();
    return {
      post: {
        data: {
          author: {
            first_name: "",
            last_name: "",
          },
          title: "",
          body: "",
        },
        meta: {
          previous_post: {
            slug: "",
            title: "",
          },
          next_post: {
            slug: "",
            title: "",
          },
        },
      },
    };
  },
  methods: {
    getPost() {
      this.butterService.getPostBySlug(this.$route.params.slug).then((res) => {
        this.post = res;
      });
    },
    nextPost() {
      this.$router.push(`/blog/${this.post.meta.next_post.slug}`);
    },
    previousPost() {
      this.$router.push(`/blog/${this.post.meta.previous_post.slug}`);
    },
  },
  created() {
    this.getPost();
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        console.log(to);
        console.log(from);
        this.getPost();
      },
    },
  },
};
</script>

<template>
  <div id="blog-post">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-6 is-vertical">
          <div class="container blogpost">
            <h1>{{ post.data.title }}</h1>
            <h4>
              {{ post.data.author.first_name }} {{ post.data.author.last_name }}
            </h4>
            <div v-html="post.data.body"></div>
          </div>
        </div>
        <div class="tile is-4 is-vertical">
          <div class="blog-button-bar">
            <b-button
              class="blog-post-button"
              v-if="post.meta.previous_post"
              label="Previous Post"
              type="is-light"
              size="is-small"
              @click="previousPost"
            />
            <b-button
              class="blog-post-button"
              v-if="post.meta.next_post"
              label="Next Post"
              type="is-light"
              size="is-small"
              @click="nextPost"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>