import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'buefy/dist/buefy.css'
//icon libs
library.add(faUserSecret)
library.add(faInstagram)
//plugin configd
Vue.use(Buefy)
Vue.use(VueGtag, {
  config: { id: "MY GOOGLE ANALYTICS ID" }
});
//register components
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
