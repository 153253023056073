import Vue from 'vue'
import Router from 'vue-router'
import SiteHome from '@/components/SiteHome'
import BlogHome from '@/components/BlogHome'
import BlogPost from '@/components/BlogPost'
import BlogAboutMePage from '@/components/BlogAboutMePage'
import BlogContactMePage from '@/components/BlogContactMePage'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'site-home',
      component: SiteHome
    },
    {
      path: '/blog/',
      name: 'blog-home',
      component: BlogHome
    },
    {
      path: '/blog/:slug',
      name: 'blog-post',
      component: BlogPost
    },
    {
      path: '/about',
      name: 'blog-about-me-page',
      component: BlogAboutMePage
    },
    {
      path: '/contact',
      name: 'blog-contact-me-page',
      component: BlogContactMePage
    }
  ]
})