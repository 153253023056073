<script>
import BlogAboutMe from "@/components/BlogAboutMe.vue";
import ButterCMSService from "@/services/ButterCMSService";
export default {
  name: "blog-home",
  components: {
    BlogAboutMe,
  },
  data() {
    this.butterService = new ButterCMSService()
    return {
      page_title: "Blog Posts",
      posts: [],
    };
  },
  methods: {
    getPosts() {
      this.butterService.listPosts().then((res) => {
        this.posts = res;
      })
    },
  },
  created() {
    this.getPosts()
  },
};
</script>

<template>
  <div id="blog-home">
    <h1 class="title">{{ page_title }}</h1>
    <br />
    <br />
    <br />
    <div class="container">
      <div class="tile is-ancestor">
        <!-- All other tile elements -->
        <div class="tile is-6 is-vertical">
          <div v-for="(post, index) in posts" :key="post.slug + '_' + index">
            <router-link :to="'/blog/' + post.slug">
              <div class="card-custom">
                <div class="card-image">
                  <figure class="image is-8x12">
                    <img
                      v-if="post.featured_image"
                      :src="post.featured_image"
                      alt=""
                    />
                    <img
                      v-else
                      src="http://via.placeholder.com/250x250"
                      alt=""
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left"></div>

                    <div class="media-content">
                      <p>Allison Twarog</p>
                    </div>
                  </div>

                  <div class="content">
                    <h2>{{ post.title }}</h2>
                    <p>{{ post.summary }}</p>
                    <br />
                    <time datetime="2016-1-1">11/1/2020</time>
                  </div>
                </div>
              </div>
              <br />
            </router-link>
          </div>
        </div>
        <div class="tile is-2 is-vertical"></div>
        <div class="tile is-4 is-vertical notification vert-tile-custom">
          <BlogAboutMe />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>