<template>
  <div id="foot">
    <footer class="footer">
      <div class="content has-text-centered">
        <p class="footer-item">
          Copyright ©2020 · Uncensored Mama - All Rights Are Reserved
        </p>
        <br/>
        <p class="footer-item poweredby">
          <a href="https://buttercms.com/">
            <img src="https://cdn.buttercms.com/PGJPyIwaQ2KnOA8UyKfH"/>
          </a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "layout-foot",
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
.poweredby {
  width: 100px;
  height: 100px;
}
</style>