<template>
  <div id="app">
    <ComingSoon/>
    <!-- <LayoutNav/>
    <LayoutHeader/>
    <div class="container-fluid router-view">
      <router-view/>
    </div> -->
    <LayoutFoot/>
  </div>
</template>

<script>
// import LayoutNav from './layout/layout-nav'
import LayoutFoot from './layout/layout-foot'
// import LayoutHeader from './layout/layout-header'
import ComingSoon from './views/ComingSoon'
export default {
  name: 'app',
  components: {
    // LayoutNav,
    LayoutFoot,
    ComingSoon
    // LayoutHeader
  }
}
</script>

<style lang="scss">
</style>
