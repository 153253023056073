const axios = require('axios').default;
module.exports = class NetworkRequests {
    constructor(){

    }

    get = async (url) => {
        if (!url){
            throw "URL is required"
        }
        try {
            return await axios.get(url)
        } catch (error) {
            console.log(error)
            return error
        }
    }

    put = async (url, obj) => {
        if (!url || !obj){
            throw "URL and Obj is required"
        }
        try {
            return await axios.put(url, obj)
        } catch (error) {
            console.log(error)
            return error
        }
    }

    post = async (url, obj) => {
        if (!url || !obj){
            throw "URL and Obj is required"
        }
        try {
            return await axios.post(url, obj)
        } catch (error) {
            console.log(error)
            return error
        }
    }

    delete = async (url, key) => {
        if (!url || !key){
            throw "URL and Key is required"
        }
        try {
            return await axios.delete(`${url}/${key}`)
        } catch (error) {
            console.log(error)
            return error
        }
    }
}