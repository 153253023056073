<script>
import BlogAboutMe from "@/components/BlogAboutMe.vue";
export default {
  name: "blog-contact-me-page",
  components: {
    BlogAboutMe,
  },
  data() {
    return {
      page_title: "Contact Me",
      posts: [],
    };
  },
  methods: {
    sendMessage() {
      this.$buefy.toast.open({
        message: "Thank you for your message and for supporting the blog - I will respond as soon as possible!",
        type: "is-success",
        position: "is-bottom-left",
        duration: 7500
      });
    },
  },
  created() {},
};
</script>

<template>
  <div id="blog-contact">
    <h1 class="title">{{ page_title }}</h1>
    <br />
    <br />
    <br />
    <!-- Create `v-for` and apply a `key` for Vue. Here we are using a combination of the slug and index. -->
    <div class="container">
      <div class="tile is-ancestor">
        <!-- All other tile elements -->
        <div class="tile is-6 is-vertical">
          <section>
            <b-field>
              <b-input placeholder="Email" type="email"></b-input>
            </b-field>

            <b-field>
              <b-input placeholder="Subject" type="text"></b-input>
            </b-field>

            <b-field>
              <b-input
                type="textarea"
                minlength="5"
                maxlength="1000"
                placeholder="Message details.."
              >
              </b-input>
            </b-field>
          </section>
          <section>
            <b-button 
            type="is-light"
            label="Send" 
            @click="sendMessage" />
          </section>
          <br />
          <br />
        </div>
        <div class="tile is-2 is-vertical"></div>
        <div class="tile is-4 is-vertical notification vert-tile-custom">
          <BlogAboutMe />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>