<template>
  <div id="blog-home">
    <p class="title">{{ page_title }}</p>
    <br />
    <br />
    <div class="container">
      <div class="header-img-container">
        <b-image
          class="header-img"
          :src="require('@/assets/allilogoheader2.jpg')"
          ratio="4by3"
        ></b-image>
      </div>
    </div>
    <div class="container">
      <div class="tile is-ancestor">
        <!-- All other tile elements -->
        <div class="tile is-6 is-vertical"></div>
        <div class="tile is-2 is-vertical"></div>
        <div class="tile is-4 is-vertical"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blog-site-home",
  components: {},
  data() {
    return {
      page_title: "Welcome to My Blog!",
      posts: [],
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss">
</style>